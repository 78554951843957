<template>
  <div>
    <Row :gutter="8" class="m-b-5">
      <i-col span="3">
        <DatePicker transfer type="date" size="small" placeholder="选择日期" style="width: 100%" v-model="actionDate">
        </DatePicker>
      </i-col>
      <i-col span="6">
        <i-input size="small" placeholder="备注" v-model='query.keyword'></i-input>
      </i-col>
      <i-col span="2">
        <Button size="small" icon="ios-search" type="primary" class="m-r-5" @click="handleSearch">搜索</Button>
      </i-col>
    </Row>
    <Table stripe max-height="420" size="small" :data="tableList" :columns="tableColumns"></Table>
    <div class="paging_style">
      <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator :current="query.pageNumber"
        @on-change="changePage"></Page>
    </div>
  </div>
</template>

<script>
import { sysMixins } from '@/assets/mixins/sys'
import { deleteWorkday } from '@/api/msp/workday'
import { downloadFileRequest } from '@/utils/download'
export default {
  mixins: [sysMixins],
  data () {
    return {
      actionDate: null,
      query: {
        workday: '',
        keyword: '',
        pageNumber: 1,
        pageSize: 15
      },
      tableList: [],
      total: 0,
      tableColumns: [
        { title: '作业令日期', key: 'workday' },
        {
          title: '作业令类型(站点数量)',
          align: 'center',
          render: (h, params) => {
            const html = []
            params.row.statisticList.forEach(item => {
              html.push(h('p', `${item.dayTypeName}(${item.stationCount})`))
            })
            return h('div', html)
          }
        },
        {
          title: '附件',
          align: 'center',
          render: (h, { row }) => {
            return h('a', {
              on: {
                click: () => {
                  this.handleDownloadFile(row)
                }
              }
            }, '附件下载')
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            const html = []
            html.push(h('a', {
              style: { marginRight: '5px' },
              on: {
                click: () => {
                  this.handleViewDetail(params.row)
                }
              }
            }, '详情'))
            if (new Date(params.row.workday + ' 18:00:00').valueOf() > new Date().valueOf()) {
              html.push(h('a', {
                style: { color: '#ef4f4f' },
                on: {
                  click: () => {
                    this.handelDelete(params.row)
                  }
                }
              }, '删除'))
            }
            return h('div', html)
          }
        }
      ]
    }
  },
  created () {
    // 设置头部路由标签
    this.setLinkTagArray()
    this.setLinkTagArray({ key: 'actionDateIndex', value: '作业令列表' })
    this.setLinkRouterMaps(['actionDateIndex', () => { this.handleIndexPage() }])
    this.setActivedTagName('actionDateIndex')
  },
  mounted () {
    this.initData()
  },
  methods: {
    initData () {
      if (this.actionDate) {
        const workDay = new Date(this.actionDate)
        this.query.workday = `${workDay.getFullYear()}-${(workDay.getMonth() + 1).toString().padStart(2, '0')}-${workDay.getDate().toString().padStart(2, '0')}`
      } else {
        this.query.workday = ''
      }
      this.$store.dispatch('getWorkdayData', this.query).then(res => {
        if (res && !res.errcode) {
          this.tableList = res.list
          this.total = res.totalRow
          this.query.pageNumber = res.pageNumber
        } else {
          this.tableList = []
          this.total = 0
          this.query.pageNumber = 1
        }
      })
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.initData()
    },
    changePage (curPage) {
      this.query.pageNumber = curPage
      this.initData()
    },
    handleDownloadFile (params) {
      const dayTypes = params.statisticList.length ? params.statisticList.map(x => x.dayType) : []
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-msp/v2/workday/getWorkDayAttaches', { workday: params.workday, dayTypes: JSON.stringify(dayTypes) }, `${params.workday}作业令附件`)
    },
    handleViewDetail (params) {
      this.$store.commit('set_cur_workday', params.workday)
      this.$store.commit('set_cur_daytypes', params.statisticList.map(x => x.dayType))
      this.setBottomComponent('detailComponent')
      this.handelDetailPage()
      // 设置头部路由标签
      this.setLinkTagArray({ key: 'detailComponent', value: '作业令详情' })
      this.setLinkRouterMaps(['detailComponent', () => { this.handelDetailPage() }])
      this.setActivedTagName('detailComponent')
    },
    handelDelete (params) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要删除该条数据？',
        onOk: () => {
          const postData = {
            workday: params.workday,
            dayTypes: JSON.stringify(params.statisticList.map(x => x.dayType))
          }
          deleteWorkday(postData).then(res => {
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '操作成功' })
              this.initData()
            }
          })
        }
      })
    },
    handleIndexPage () {
      this.setShowLeftBlock(true)
      this.setShowRightBlock(false)
      this.setLeftComponent('leftComponent')
      this.setBottomComponent('listComponent')
      this.setBottomHeight('300px')
    },
    handelDetailPage () {
      this.setLeftComponent('')
      this.setShowLeftBlock(false)
      this.setShowRightBlock(false)
      this.setBottomComponent('detailComponent')
    }
  }
}
</script>
